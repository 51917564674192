import { decodeJwt } from 'jose';
import { GetServerSidePropsContext } from 'next';
import { getTokensFromObject } from 'next-firebase-auth-edge/lib/next/tokens';

import { SHOW_SSO, SSO_TOKEN } from '@mwell-healthhub/commons';
import { authConfig } from '@mwell-healthhub/commons/config/serverConfig';

export async function getToken(context: GetServerSidePropsContext) {
  if (SHOW_SSO) {
    const cookies = context.req.cookies;

    return cookies?.[SSO_TOKEN];
  } else {
    const tokens = await getTokensFromObject(context.req.cookies, authConfig);

    return tokens?.token;
  }
}

export function getTimeRemainingFromToken(token?: string): number {
  try {
    if (!token) {
      return 0;
    }

    const payload = decodeJwt(token);
    const expirationTime = payload.exp;

    if (!expirationTime) {
      throw new Error('Token does not have an expiration time');
    }

    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const timeRemaining = expirationTime - currentTimeInSeconds;

    return Math.max(timeRemaining, 0);
  } catch (error) {
    console.error('Error getting time remaining from token:', error);
    return 0;
  }
}
