import { ReactNode } from 'react';

import { AuthLayout } from '@mwell-healthhub/commons';

export interface AuthWrapperProps {
  children: ReactNode;
}

function AuthWrapper(props: AuthWrapperProps) {
  const { children } = props;
  return (
    <div className="h-screen bg-gradient-to-b from-primary-500 to-magentaBlue">
      <AuthLayout isInvertIcon>{children}</AuthLayout>
    </div>
  );
}
export default AuthWrapper;
