import { useEffect, useState } from 'react';

import { SsoLoginDto } from '@healthhub/api-lib';
import * as Sentry from '@sentry/nextjs';
import { ArrowRight } from 'iconoir-react';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import { useForm } from 'react-hook-form';

import {
  SSO_OTP_TOKEN,
  MaskedTextInput,
  SEOHead,
  TextInput,
  toast,
  useRouter,
  Button,
  OtpForm,
  SSO_TOKEN,
  SSO_REFRESH_TOKEN,
} from '@mwell-healthhub/commons';
import Loader from '@mwell-healthhub/commons/components/Loader';

import CentralizedAuthLayout from '../../components/layouts/CentralizedAuthLayout';
import { Routes } from '../../constants';
import { useSsoVerifyOtp } from '../../hooks';
import {
  useSsoAuthenticate,
  useSsoLogin,
  useSsoResendOtpEmail,
} from '../../hooks/mutations/useAuthMutations';
import { getTimeRemainingFromToken } from '../../utils/auth/get-token';

type SsoOtpFormPageData = {
  code: string;
  shouldRememberDevice: boolean;
};

type SsoOtpFormPageProps = {
  sessionId?: string;
};

type SsoLoginFormPageProps = {
  sessionId?: string;
};

export function SsoLoginFormPage(props: SsoLoginFormPageProps) {
  const { register, handleSubmit } = useForm<SsoLoginDto>();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(true);

  const { mutate: login, isLoading: isLoggingIn } = useSsoLogin({
    onSuccess: (token) => {
      sessionStorage.setItem(SSO_OTP_TOKEN, token);
      router.push({
        pathname: Routes.OTP,
        query: router.query,
      });
    },
    onError: (error) => {
      toast({
        message: get(
          error,
          'response.data.message',
          'An unknown error occurred. Please try again later.',
        ),
        type: 'error',
      });
    },
  });

  const { mutate: silentAuth } = useSsoAuthenticate({
    onSuccess: (redirectUrl) => {
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }

      setIsLoading(false);
    },
    onError: (error) => {
      Sentry.captureException(error);

      toast({
        message: 'An error occured while trying to authenticate you to another mWell Admin Portal',
        type: 'error',
      });

      router.push(Routes.LOGIN);
    },
  });

  useEffect(() => {
    if (router.isReady) {
      const ssoToken = Cookies.get(SSO_TOKEN) || '';
      const sessionId = router.query.sessionId || '';

      silentAuth({
        loginSessionToken: sessionId,
        accessToken: ssoToken,
      });
    }
  }, [router.isReady]);

  const onSubmit = (dto: SsoLoginDto) => {
    login({ ...dto, sessionId: props.sessionId });
  };

  if (isLoading) {
    return <div />;
  }

  return (
    <CentralizedAuthLayout>
      <SEOHead description="Login for mWell Operations" title="Login for Operations | mWell" />
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-1 md:gap-4">
          <div className="text-2xl font-semibold md:text-4xl">Login to your account</div>
          <div className="md:text-md text-sm text-neutral-500">Welcome back!</div>
        </div>
        <form method="POST" onSubmit={handleSubmit(onSubmit)}>
          <fieldset className="contents">
            <legend className="sr-only">Login Form</legend>
            <div className="mb-6 flex flex-col gap-2 md:mb-10 md:gap-4">
              <TextInput
                {...register('email', { required: true })}
                type="text"
                id="email"
                className="md:h-12 md:p-4"
                placeholder="Email address"
                label="Email"
                autoComplete="username"
                labelShown={true}
                labelSize="md"
                labelFontWeight="md"
                labelClassName="mb-3"
              />
              <MaskedTextInput
                {...register('password', { required: true })}
                placeholder="Password"
                labelClassName="mb-3"
                className="md:h-12 md:p-4"
                autoComplete="current-password"
                label="Password"
                labelShown={true}
                labelSize="md"
                labelFontWeight="md"
                id="trebuchet"
              />
            </div>
            <div className="flex gap-4">
              <div className="w-full">
                <Button
                  type="submit"
                  variant="primary"
                  size="lg"
                  className="text-md w-full md:text-lg"
                  iconRight={ArrowRight}
                  isLoading={isLoggingIn}
                >
                  Log in
                </Button>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </CentralizedAuthLayout>
  );
}

export function SsoOtpFormPage(prop: SsoOtpFormPageProps) {
  const { sessionId } = prop;
  const { register, handleSubmit: handleSubmitOtpForm } = useForm<SsoOtpFormPageData>();
  const [ssoOtpToken, setSsoOtpToken] = useState<string>('');
  const timeRemainingSeconds = getTimeRemainingFromToken(ssoOtpToken);

  const router = useRouter();

  useEffect(() => {
    const ssoOtpTokenSessionStorage = sessionStorage.getItem(SSO_OTP_TOKEN) as string;

    setSsoOtpToken(ssoOtpTokenSessionStorage);
  }, []);

  const { mutate: verifyEmailOtp, isLoading: isVerifying } = useSsoVerifyOtp({
    onSuccess: async ({ accessToken, redirectUrl, refreshToken }) => {
      Cookies.set(SSO_TOKEN, accessToken);
      Cookies.set(SSO_REFRESH_TOKEN, refreshToken);
      sessionStorage.removeItem(SSO_OTP_TOKEN);

      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        window.location.reload();
      }
    },
    onError: () => {
      toast({
        message: 'Invalid OTP. Please try again.',
        type: 'error',
      });
    },
  });

  const { mutate: resendEmail, isLoading: isResending } = useSsoResendOtpEmail({
    onSuccess: async (newSsoOtpToken) => {
      sessionStorage.setItem(SSO_OTP_TOKEN, newSsoOtpToken);
      setSsoOtpToken(newSsoOtpToken);
    },
    onError: () => {
      toast({
        message: 'Something went wrong. Please login again.',
        type: 'error',
      });

      sessionStorage.removeItem(SSO_OTP_TOKEN);
      router.push(Routes.LOGIN);
    },
  });

  const onVerifyOtp = (data: SsoOtpFormPageData) => {
    verifyEmailOtp({
      dto: {
        otpCode: data.code,
        sessionId,
      },
      accessToken: ssoOtpToken,
    });
  };

  const handleClickCancel = () => {
    sessionStorage.removeItem(SSO_OTP_TOKEN);

    router.push({
      pathname: Routes.LOGIN,
      query: router.query,
    });
  };

  const handleClickResend = () => {
    resendEmail({ sessionId, accessToken: ssoOtpToken });
  };

  const isLoading = isResending || isVerifying;

  return (
    <CentralizedAuthLayout>
      <SEOHead description="OTP Login | mWell" title="OTP Login | mWell" />
      {isLoading && <Loader />}
      <div className="flex flex-col gap-8 md:gap-10">
        <div className="flex flex-col gap-1 md:gap-4">
          <div className="text-2xl font-semibold md:text-4xl">OTP Verification</div>
          <div className="text-sm text-neutral-500">
            Enter the 6-digit OTP we sent to your email. Not the right email?{' '}
            <span className="cursor-pointer text-primary-500 underline" onClick={handleClickCancel}>
              Change email
            </span>
          </div>
        </div>
        <OtpForm
          isRememberDeviceHidden
          isLoading={isLoading}
          timeRemainingSeconds={timeRemainingSeconds}
          handleClickCancel={handleClickCancel}
          onClickResend={handleClickResend}
          onSubmit={handleSubmitOtpForm(onVerifyOtp)}
          register={register}
        />
      </div>
    </CentralizedAuthLayout>
  );
}
