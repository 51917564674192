import { ReactNode } from 'react';

import dynamic from 'next/dynamic';

export interface AuthLayoutProps {
  children: ReactNode;
}

function CentralizedAuthLayout(props: AuthLayoutProps) {
  const { children } = props;

  return (
    <div className="flex h-screen w-full flex-col justify-center overflow-y-auto bg-[#ECF3F9] md:items-center">
      <div className="relative p-0 md:p-20">
        <div className="z-1 absolute -top-11 right-4 hidden h-[164px] w-[164px] bg-top-right-dots bg-contain bg-no-repeat md:block" />
        <div className="z-1 absolute bottom-0 left-9 hidden h-[115px] w-[115px] bg-lower-left-dots bg-contain bg-no-repeat md:block" />
        <div className="z-2 relative m-4 flex flex-col gap-6 rounded-xl bg-neutral-50 p-8 shadow-authCard md:m-0 md:w-[606px] md:gap-12 md:p-14">
          <MwellLogoClient />
          {children}
        </div>
      </div>
    </div>
  );
}

const MwellLogo: React.FC = () => {
  const logoSrc = '/static-assets/images/mwell-logo.svg';

  return (
    // eslint-disable-next-line @next/next/no-img-element
    <img src={logoSrc} alt="mWell" className="h-[43px] w-[110px] md:h-[43px] md:w-[148px]" />
  );
};

const MwellLogoClient = dynamic(() => Promise.resolve(MwellLogo), { ssr: false });

export default CentralizedAuthLayout;
