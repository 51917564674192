import * as Sentry from '@sentry/nextjs';
import { FirebaseError } from 'firebase/app';
import Cookies from 'js-cookie';
import get from 'lodash.get';
import Link from 'next/link';
import { useForm } from 'react-hook-form';

import { AuthForm, SEOHead, toast, translateFirebaseError } from '@mwell-healthhub/commons';
import { OTP_TOKEN, OtpForm } from '@mwell-healthhub/commons';
import { useFirebaseAuth } from '@mwell-healthhub/commons/auth/firebase';
import Loader from '@mwell-healthhub/commons/components/Loader';

import { useResendEmailOtp, useUserLogout, useVerifyEmailOtp, useLogin } from '../../hooks';
import AuthWrapper from '../layouts/AuthWrapper';

type FirebaseOtpPageFormData = {
  code: string;
  shouldRememberDevice: boolean;
};

type FirebaseLoginFormPageData = {
  email: string;
  password: string;
};

export function FirebaseLoginFormPage() {
  const { register, handleSubmit } = useForm<FirebaseLoginFormPageData>();
  const { getFirebaseAuth } = useFirebaseAuth();
  const auth = getFirebaseAuth(process.env.NEXT_PUBLIC_TENANT_ID);

  const { mutate, isLoading: isLoggingIn } = useLogin(auth, {
    onSuccess: () => {
      window.location.reload();
    },
    onError: (error) => {
      Sentry.captureException(error);

      toast({
        message: translateFirebaseError((error as FirebaseError).code),
        type: 'error',
      });
    },
  });

  const onSubmit = (data: FirebaseLoginFormPageData) => {
    mutate(data);
  };

  return (
    <AuthWrapper>
      <SEOHead description="Login for Operations" title="Login for Operations | HealthHub" />
      {isLoggingIn && <Loader />}
      <div className="flex flex-col p-5 py-16 md:mt-0 md:justify-between md:px-14">
        <div>
          <div className="mb-[34px]">
            <h4 className="mb-1.5 text-2xl font-semibold md:text-3xl">Sign in</h4>
            <div className="hidden font-medium text-gray41">
              New user?{' '}
              <Link href="" className="text-primary3">
                Create an account
              </Link>
            </div>
          </div>
          <AuthForm onSubmit={handleSubmit(onSubmit)} register={register} />
        </div>
      </div>
    </AuthWrapper>
  );
}

export function FirebaseOtpPageForm() {
  const { register, handleSubmit: handleSubmitOtpForm } = useForm<FirebaseOtpPageFormData>();
  const { getFirebaseAuth } = useFirebaseAuth();
  const auth = getFirebaseAuth(process.env.NEXT_PUBLIC_TENANT_ID);
  const { mutate: logoutUser } = useUserLogout(auth);

  const { mutate: verifyEmailOtp, isLoading: isVerifying } = useVerifyEmailOtp({
    onSuccess: (data) => {
      Cookies.set(OTP_TOKEN, data.otpToken);

      window.location.reload();
    },
    onError: (err) => {
      toast({
        message: get(err, 'response.data.message', 'Something went wrong'),
        type: 'error',
      });
    },
  });

  const { mutate: resendEmail, isLoading: isResending } = useResendEmailOtp({
    onError: (err) => {
      toast({
        message: get(err, 'response.data.message', 'Something went wrong'),
        type: 'error',
      });
    },
  });

  const onVerifyOtp = (data: FirebaseOtpPageFormData) => {
    verifyEmailOtp(data);
  };

  const handleClickCancel = () => {
    logoutUser({});
  };

  const handleClickResend = () => {
    resendEmail({ token: undefined });
  };

  const isLoading = isResending || isVerifying;

  return (
    <AuthWrapper>
      <SEOHead description="OTP Login" title="OTP Login | HealthHub" />
      {isLoading && <Loader />}
      <div className="mt-10 flex h-full flex-col px-14 py-[70px] md:mt-0 md:justify-between">
        <div>
          <div className="mb-[34px]">
            <h4 className="mb-1.5 text-2xl font-semibold md:text-3xl">OTP Verification</h4>
            <div className="text-sm text-gray41 md:text-base">
              Enter the 6-digit OTP we sent to your email. Not the right email?{' '}
              <span
                className="cursor-pointer text-primary-500 underline"
                onClick={handleClickCancel}
              >
                Change email
              </span>
            </div>
          </div>
          <OtpForm
            isLoading={isLoading}
            handleClickCancel={handleClickCancel}
            onClickResend={handleClickResend}
            onSubmit={handleSubmitOtpForm(onVerifyOtp)}
            register={register}
          />
        </div>
      </div>
    </AuthWrapper>
  );
}
